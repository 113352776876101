'use client'
import {useSearchParams, useRouter} from 'next/navigation'
import HgDropdown from '../HgDropdown'
import useQueryStringGenerator from '~/design-system/hooks/useQueryStringGenerator'

type HgItemsPerPageProps = {
  perPageOptions?: number[]
  onPerPageChange?: (perPage: number) => void
  perPage?: number
  resultsLength: number
  currentPage: number
}

function formatNumber(number: number) {
  return number.toString().padStart(2, '0')
}

export default function HgItemsPerPage({
  perPageOptions = [10, 25, 50],
  onPerPageChange,
  perPage = 10,
  resultsLength,
  currentPage,
}: HgItemsPerPageProps) {
  const searchParams = useSearchParams()
  const createQueryString = useQueryStringGenerator()
  const router = useRouter()

  const currentPerPage =
    perPage ?? parseInt(searchParams.get('perPage') || perPageOptions[0].toString())
  const currentWindowStart = Math.min(
    (currentPage - 1) * currentPerPage + 1,
    resultsLength
  )
  const currentWindowEnd = Math.min(currentPage * currentPerPage, resultsLength)

  return (
    <div className="flex w-[310px] items-center gap-s3">
      <span>Items per page</span>
      <HgDropdown
        options={[...perPageOptions, resultsLength].map(option => ({
          value: option.toString(),
          label: option === resultsLength ? 'All' : formatNumber(option),
        }))}
        value={currentPerPage.toString()}
        onChange={value => {
          router.push(createQueryString('perPage', value))
          onPerPageChange?.(parseInt(value))
        }}
        classNames={{trigger: '[&_span]:w-16'}}
      />
      <span>
        {formatNumber(currentWindowStart)}–{formatNumber(currentWindowEnd)} of{' '}
        {formatNumber(resultsLength)}
      </span>
    </div>
  )
}
