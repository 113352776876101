'use client'

import {zodResolver} from '@hookform/resolvers/zod'
import {
  useEffect,
  useState,
  type BaseSyntheticEvent,
  type DetailedHTMLProps,
  type FormEvent,
  type FormHTMLAttributes,
  type ReactNode,
} from 'react'
// eslint-disable-next-line no-restricted-imports
import {useForm} from 'react-hook-form'
import {tv} from 'tailwind-variants'
import {type OmitStrict} from 'type-zoo'
import * as z from 'zod'
import {
  EmailInput,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormProvider,
} from '~/design-system/foundations'
import {
  HgButton,
  HgIcon,
  type HgButtonVariants,
  type HgTextLinkProps,
} from '~/design-system/hg/components'
import {cn, refineEmail} from '~/design-system/utils'
import {HgButtonLink, type SizeDependentIconProps} from '../HgButton'

export type HgEmailFormData = {
  email: string
}

export const emailFormSchema: z.Schema<HgEmailFormData> = z.object({
  email: z.string().refine(...refineEmail),
})

const wrapperVariants = tv({
  base: 'grid h-48 min-h-48 w-full min-w-[298px] max-w-[448px] grid-cols-[1fr_auto] items-center gap-x-16 rounded-4xl border border-border-frosted bg-surface-frosted pe-[3px] outline-2 outline-border-focus backdrop-blur-[20px] transition-colors duration-300 ease-out focus-within:bg-surface-frosted-hover focus-within:outline hover:border-border-frosted hover:bg-surface-frosted-hover has-[button:focus]:bg-surface-frosted  has-[button:focus]:outline-none sm:w-fit sm:min-w-[411px]',
  variants: {
    active: {true: 'outline-none outline-0'},
    secondaryLink: {true: 'h-40 min-h-40'},
  },
})

const layoutVariants = tv({
  slots: {
    formItemWrapper: 'flex flex-col',
    buttonContainer:
      'mt-s3 grid w-full min-w-[298px] max-w-[448px] grid-cols-2 gap-s2',
    stackedPrimaryButton: 'w-full',
    secondaryButton: 'w-full whitespace-nowrap',
    formItem: '',
    embeddedPrimaryButton: 'hidden',
  },
  variants: {
    alwaysStacked: {
      false: {
        formItemWrapper: 'sm:flex-row sm:items-center sm:space-x-s2',
        formItem: 'sm:h-48 sm:min-h-48',
        buttonContainer: 'sm:mt-0 sm:flex sm:min-w-0 sm:gap-0',
        stackedPrimaryButton: 'sm:hidden',
        secondaryButton: 'sm:w-auto',
        embeddedPrimaryButton: 'sm:flex',
      },
    },
  },
})

export type HgEmailCaptureVariant = 'primary' | 'secondary'

export type HgEmailCaptureOnSubmit = (
  values: HgEmailFormData,
  e: BaseSyntheticEvent<object, any, any> | undefined
) => unknown

export type HgEmailCaptureProps = {
  variant: HgEmailCaptureVariant
  secondaryLinkProps?: OmitStrict<
    HgTextLinkProps,
    'forceActive' | 'forceFocusVisible' | 'forceHover'
  >
  classNames?: {
    formItemWrapper?: string
  }
  alwaysStacked?: boolean
  labelText: string
  submitButtonText: string
  successMessage?: ReactNode
  formProps: Omit<
    DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>,
    'onSubmit'
  > & {
    onSubmit?: HgEmailCaptureOnSubmit
    [key: string]: unknown
  }
}

const HgEmailCapture = (props: HgEmailCaptureProps) => {
  const {
    classNames,
    labelText,
    submitButtonText,
    successMessage,
    variant,
    formProps,
    alwaysStacked = false,
  } = props
  const {
    formItem,
    secondaryButton,
    stackedPrimaryButton,
    buttonContainer,
    formItemWrapper,
    embeddedPrimaryButton,
  } = layoutVariants({alwaysStacked})
  const formHasAction = !!formProps.action
  const noValidate = !!formProps.noValidate

  const [isFieldActive, setIsFieldActive] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const form = useForm<HgEmailFormData>({
    resolver: noValidate ? undefined : zodResolver(emailFormSchema),
    mode: 'onTouched',
    defaultValues: {
      email: '',
    },
  })

  const {
    formState: {errors, isDirty, isSubmitting},
    reset,
    clearErrors,
  } = form

  useEffect(() => {
    if (isDirty) {
      setShowSuccessMessage(false)
    }
  }, [isDirty])

  const validationErrorMessage = errors.email?.message
  const serverErrorMessage = errors.root?.message
  const displayedErrorMessage = validationErrorMessage ?? serverErrorMessage

  const getSubmitButtonProps = (
    variant: HgEmailCaptureVariant
  ): SizeDependentIconProps & {variant: HgButtonVariants['variant']} => {
    return variant === 'secondary'
      ? {
          'variant': 'secondary',
          'size': 'icon',
          'iconProps': {
            iconType: 'arrow-right',
          },
          'aria-label': 'Submit',
        }
      : {size: 'default', variant: 'primary'}
  }

  const setFieldActive = () => {
    setIsFieldActive(true)
  }

  async function handleSubmit(e: FormEvent) {
    if (!formHasAction) {
      e.preventDefault()
    }

    /**
     * NOTE: We are overriding `preventDefault` because react-hook-form's `handleSubmit` calls this, but
     * we want more granular control of when it's called. We have some cases where we want the form's
     * native submit ability to work while still having an `onSubmit` to track analytics
     */
    e.preventDefault = () => {}

    await form.handleSubmit(async (data, e) => {
      try {
        if (formProps.onSubmit) {
          await formProps.onSubmit(data, e)
        }

        // reset the form on success, but don't reset the field if we are relying on the browsers native submit
        if (!formHasAction) {
          reset()
          setShowSuccessMessage(true)
        }
      } catch (err) {
        // reset the form with the data to get rid of the loading state
        reset(data)
        if (err instanceof Error) {
          form.setError('root', {message: err.message})
        }
        form.setError('root', {message: 'An unknown error occurred.'})
      }
    })(e)
  }

  function renderSubmit(className?: string) {
    return (
      <HgButton
        type="submit"
        className={className}
        uiState={isSubmitting ? 'pending' : undefined}
        {...getSubmitButtonProps(variant)}
      >
        {submitButtonText}
      </HgButton>
    )
  }

  function renderSecondaryLink() {
    if (props.variant !== 'primary' || !props.secondaryLinkProps) {
      return null
    }

    return (
      <div className={buttonContainer()}>
        {renderSubmit(stackedPrimaryButton())}
        <HgButtonLink
          {...props.secondaryLinkProps}
          className={secondaryButton()}
          variant="secondary"
        />
      </div>
    )
  }

  return (
    <FormProvider {...form}>
      <form
        {...formProps}
        onSubmit={handleSubmit}
        noValidate
        className="relative w-full"
      >
        <FormField
          control={form.control}
          name="email"
          render={({field}) => {
            return (
              <>
                <div className={cn(formItemWrapper(), classNames?.formItemWrapper)}>
                  <FormItem
                    className={cn(
                      wrapperVariants({
                        active: isFieldActive,
                        secondaryLink: !!props.secondaryLinkProps,
                      }),
                      formItem()
                    )}
                  >
                    <FormLabel className="sr-only">{labelText}</FormLabel>
                    <FormControl>
                      <EmailInput
                        data-1p-ignore
                        autoComplete="email"
                        placeholder={labelText}
                        className="h-full w-full rounded-s-4xl bg-transparent-transparent ps-20 text-text-default outline-none arcadia-ui-1 placeholder:text-text-subdued"
                        {...field}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                          if (serverErrorMessage) {
                            clearErrors('root')
                          }
                          setFieldActive()
                          field.onChange(e)
                        }}
                        onPointerDown={setFieldActive}
                        onBlur={() => {
                          setIsFieldActive(false)
                          field.onBlur()
                        }}
                      />
                    </FormControl>
                    <input type="hidden" name="path" value="signup" />
                    {renderSubmit(
                      cn(
                        variant === 'primary' && props.secondaryLinkProps
                          ? embeddedPrimaryButton()
                          : 'max-w-fit'
                      )
                    )}
                  </FormItem>
                  {renderSecondaryLink()}
                </div>
                {successMessage && showSuccessMessage && !displayedErrorMessage && (
                  <FormMessage className="absolute top-[calc(100%+16px)] text-text-default arcadia-ui-1">
                    <HgIcon size="regular" className="me-8" iconType="success" />
                    {successMessage}
                  </FormMessage>
                )}
                {displayedErrorMessage && (
                  <FormMessage className="absolute top-[calc(100%+16px)] text-text-default arcadia-ui-1">
                    <HgIcon size="regular" className="me-8" iconType="error" />
                    {displayedErrorMessage}
                  </FormMessage>
                )}
              </>
            )
          }}
        />
      </form>
    </FormProvider>
  )
}

export default HgEmailCapture
