import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '~/design-system/foundations/Breadcrumb'
import HgTextLink, {type HgTextLinkProps} from '../HgTextLink'
import {HgBreadcrumbOverflowMenu} from './HgBreadcrumbOverflowMenu'

const NUM_VISIBLE_BREADCRUMBS = 3

export type BreadcrumbLinkProps = {
  label: React.ReactNode
  link: Omit<HgTextLinkProps, 'variant' | 'children'>
}
export type HgBreadcrumbProps = {
  breadcrumbs: BreadcrumbLinkProps[]
} & React.HTMLAttributes<HTMLDivElement>

function HgBreadcrumbItem({
  breadcrumb,
  withSeparator,
}: {
  breadcrumb: BreadcrumbLinkProps
  withSeparator: boolean
}) {
  return (
    <>
      <BreadcrumbItem>
        <BreadcrumbLink asChild>
          <HgTextLink href={breadcrumb.link.href} variant="inline">
            {breadcrumb.label}
          </HgTextLink>
        </BreadcrumbLink>
      </BreadcrumbItem>
      {withSeparator && <BreadcrumbSeparator className="px-s2 text-icon-subdued" />}
    </>
  )
}

function HgBreadcrumbListDefault({
  breadcrumbs,
}: {
  breadcrumbs: BreadcrumbLinkProps[]
}) {
  return breadcrumbs.map((breadcrumb, index) => (
    <HgBreadcrumbItem
      key={index}
      breadcrumb={breadcrumb}
      withSeparator={index < breadcrumbs.length - 1}
    />
  ))
}

function HgBreadcrumbListOverflow({
  breadcrumbs,
}: {
  breadcrumbs: BreadcrumbLinkProps[]
}) {
  const numOverflowBreadcrumbs = breadcrumbs.length - NUM_VISIBLE_BREADCRUMBS
  return (
    <>
      <HgBreadcrumbItem breadcrumb={breadcrumbs[0]} withSeparator={true} />
      <BreadcrumbItem>
        <HgBreadcrumbOverflowMenu
          breadcrumbs={breadcrumbs.slice(1, 1 + numOverflowBreadcrumbs)}
        />
      </BreadcrumbItem>
      <BreadcrumbSeparator className="px-s2 text-icon-subdued" />
      <HgBreadcrumbListDefault
        breadcrumbs={breadcrumbs.slice(1 + numOverflowBreadcrumbs)}
      />
    </>
  )
}

export default function HgBreadcrumb({
  breadcrumbs,
  ...htmlProps
}: HgBreadcrumbProps) {
  const hasOverflow = breadcrumbs.length > NUM_VISIBLE_BREADCRUMBS
  return (
    <Breadcrumb {...htmlProps}>
      <BreadcrumbList className="flex items-center text-text-subdued arcadia-ui-1">
        {hasOverflow ? (
          <HgBreadcrumbListOverflow breadcrumbs={breadcrumbs} />
        ) : (
          <HgBreadcrumbListDefault breadcrumbs={breadcrumbs} />
        )}
      </BreadcrumbList>
    </Breadcrumb>
  )
}
