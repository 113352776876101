export const fontWeight = {
  320: '320',
  360: '360',
  400: '400',
  420: '420',
  440: '440',
  480: '480',
  500: '500',
  book: '400',
  regular: '450',
  medium: '530',
}
