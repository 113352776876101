'use client'

import {usePathname, useSearchParams} from 'next/navigation'
import {useCallback} from 'react'

const useQueryStringGenerator = () => {
  const searchParams = useSearchParams()
  const pathname = usePathname()

  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams.toString())
      params.set(name, value)

      return `${pathname}?${params.toString()}`
    },
    [searchParams, pathname]
  )

  return createQueryString
}

export default useQueryStringGenerator
