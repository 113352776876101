'use client'
import {useTexture} from '@react-three/drei'
import {useState} from 'react'
import {tv, type VariantProps} from 'tailwind-variants'
import {
  Button,
  LinkWrapper,
  type ButtonProps,
  type LinkWrapperProps,
} from '~/design-system/foundations'
import {
  HgIcon,
  HgLoadingDots,
  StableFluidBackground,
  type HgIconType,
} from '~/design-system/hg/components'
import {purple} from '~/design-system/hg/tokens/colors/colors'

// ensure the button background map is available when the page loads
useTexture.preload('/design-system/hg/button-stable-fluid-gradient-bg.png')

const hgButtonVariants = tv({
  slots: {
    button:
      'group inline-flex h-40 w-fit items-center justify-center whitespace-nowrap text-text-default outline-2 outline-offset-4 outline-border-focus transition-colors duration-300 ease-out arcadia-ui-1 focus-visible:outline aria-busy:cursor-default aria-disabled:cursor-default',
    content: '',
  },
  variants: {
    variant: {
      primary: {
        button: `relative overflow-hidden rounded-4xl bg-surface-primary text-text-on-primary hover:bg-surface-primary-hover focus-visible:bg-surface-primary-hover active:bg-surface-primary-active aria-busy:bg-surface-primary-active aria-disabled:bg-surface-primary-disabled`,
        content: 'text-icon-on-primary',
      },
      secondary: {
        button: `group rounded-5xl border border-border-frosted bg-surface-frosted backdrop-blur-[20px] hover:bg-surface-frosted-hover focus-visible:bg-surface-frosted-hover active:bg-surface-frosted-active aria-busy:bg-surface-frosted-active aria-disabled:bg-surface-frosted aria-disabled:text-text-disabled`,
        content: 'text-icon-default group-aria-disabled:text-icon-disabled',
      },
      tertiary: {
        button: `group rounded-5xl bg-surface-elevated shadow-[0px_0px_3px_0px_rgba(28,28,35,0.09),0px_6px_10px_0px_rgba(28,28,35,0.04),0px_10px_16px_0px_rgba(28,28,35,0.02)] hover:bg-surface-elevated-hover focus-visible:bg-surface-elevated-hover active:bg-surface-elevated-active aria-busy:bg-surface-elevated-active aria-disabled:text-text-disabled`,
        content: 'text-icon-default group-aria-disabled:text-icon-disabled',
      },
    },
    size: {
      default: {button: 'px-20'},
      icon: {button: 'max-h-40 min-h-40 min-w-40 max-w-40 rounded-full'},
      input: {
        button:
          'max-h-24 min-h-24 min-w-24 max-w-24 rounded-full border-0 bg-transparent-transparent shadow-none outline-offset-1 aria-disabled:bg-transparent-transparent',
      },
    },
    uiState: {
      focused: {
        button: 'outline',
      },
      active: {button: ''},
      disabled: {button: ''},
      hovered: {button: ''},
      pending: {button: ''},
    },
    position: {
      left: {content: 'mr-8'},
      center: {content: ''},
      right: {content: 'ml-8'},
    },
  },
  compoundVariants: [
    {
      variant: 'primary',
      uiState: 'focused',
      class: {button: 'bg-surface-primary-hover'},
    },
    {
      variant: 'primary',
      uiState: 'active',
      class: {
        button: 'focus-visible:surface-primary-active bg-surface-primary-active',
      },
    },
    {
      variant: 'secondary',
      uiState: 'active',
      class: {button: 'bg-surface-frosted-active'},
    },
    {
      variant: 'tertiary',
      uiState: 'active',
      class: {button: 'bg-surface-elevated-active'},
    },
    {
      variant: 'secondary',
      uiState: 'focused',
      class: {button: 'bg-surface-frosted-hover'},
    },
    {
      variant: 'tertiary',
      uiState: 'focused',
      class: {button: 'bg-surface-elevated-hover'},
    },
    {
      variant: 'primary',
      uiState: 'hovered',
      class: {button: 'bg-surface-primary-hover'},
    },
    {
      variant: 'secondary',
      uiState: 'hovered',
      class: {button: 'bg-surface-frosted-hover'},
    },
    {
      variant: 'tertiary',
      uiState: 'hovered',
      class: {button: 'bg-surface-elevated-hover'},
    },
  ],
  defaultVariants: {
    variant: 'primary',
    size: 'default',
  },
})

export type HgButtonVariants = VariantProps<typeof hgButtonVariants>

type HgButtonBaseProps = Partial<ButtonProps> & HgButtonVariants

type ButtonIconProps = {
  iconType: HgIconType
}

export type DefaultSizeButtonIconPosition = 'left' | 'right'
type DefaultSizeButtonIconProps = {
  position: DefaultSizeButtonIconPosition
} & ButtonIconProps

type IconOrInputSizeButtonProps = {
  'size': 'icon' | 'input'
  'iconProps': ButtonIconProps
  /**
   * Describe the button's purpose for screen readers for icon only buttons
   */
  'aria-label': string
}

export type DefaultSizeSpecificButtonProps = {
  size?: 'default'
  iconProps?: DefaultSizeButtonIconProps
}

export type SizeDependentIconProps =
  | IconOrInputSizeButtonProps
  | DefaultSizeSpecificButtonProps

export type HgButtonProps = Omit<HgButtonBaseProps, 'asChild' | 'size'> &
  SizeDependentIconProps

function ButtonContent({
  iconProps,
  children,
  uiState,
  variant,
  size,
}: {
  iconProps?: ButtonIconProps | DefaultSizeButtonIconProps
  children: React.ReactNode
  uiState: HgButtonVariants['uiState']
  variant: HgButtonVariants['variant']
  size?: 'default' | 'icon' | 'input'
}) {
  const {content} = hgButtonVariants({variant})

  const iconSize = size === 'input' ? 'small' : 'regular'

  if (uiState === 'pending') {
    if (!iconProps) {
      return <HgLoadingDots className={content()} />
    }

    return <HgIcon className={content()} size={iconSize} iconType="loading" />
  }

  if (!iconProps) {
    return children
  }

  if ('position' in iconProps) {
    return (
      <>
        {iconProps.position === 'left' && (
          <HgIcon
            size="regular"
            iconType={iconProps.iconType}
            className={content({position: iconProps.position})}
          />
        )}
        {children}
        {iconProps.position === 'right' && (
          <HgIcon
            size="regular"
            iconType={iconProps.iconType}
            className={content({position: iconProps.position})}
          />
        )}
      </>
    )
  }
  return (
    <HgIcon className={content()} size={iconSize} iconType={iconProps.iconType} />
  )
}

function HgButtonFluidBackground() {
  return (
    <div className="absolute inset-[-2px] z-0 hidden items-center justify-center opacity-0 transition-opacity duration-500 group-hover:opacity-100 group-active:opacity-75 group-aria-busy:opacity-0 group-aria-disabled:opacity-0 sm:block">
      <StableFluidBackground
        cursorSize={39}
        mouseForce={20}
        poissonIterations={18}
        resolution={0.9}
        gradientMapPath="/design-system/hg/button-stable-fluid-gradient-bg.png"
        backgroundColorHex={purple.magic[700]}
      />
    </div>
  )
}

function HgPrimaryButtonContent({
  iconProps,
  children,
  uiState,
}: {
  iconProps?: DefaultSizeButtonIconProps | ButtonIconProps
  children: React.ReactNode
  uiState: HgButtonVariants['uiState']
}) {
  return (
    <>
      <HgButtonFluidBackground />
      <div className="align-center pointer-events-none relative z-[1] flex max-h-fit max-w-fit text-nowrap">
        <ButtonContent variant="primary" uiState={uiState} iconProps={iconProps}>
          {children}
        </ButtonContent>
      </div>
    </>
  )
}
export default function HgButton(props: HgButtonProps) {
  const [isActive, setIsActive] = useState(false)
  const {
    className,
    variant = 'primary',
    size,
    uiState,
    disabled,
    children,
    iconProps,
    onClick,
    onKeyDown,
    onKeyUp,
    ...buttonProps
  } = props

  const isDisabled = uiState === 'disabled' || disabled

  const {button} = hgButtonVariants({
    variant,
    size,
    uiState: isActive ? 'active' : uiState,
  })

  return (
    <Button
      data-button-variant={variant}
      className={button({class: className})}
      aria-disabled={isDisabled}
      aria-busy={uiState === 'pending'}
      onClick={e => {
        if (isDisabled || uiState === 'pending') {
          e.preventDefault()
          return
        }

        onClick?.(e)
      }}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          setIsActive(true)
        }
        onKeyDown?.(e)
      }}
      onKeyUp={e => {
        setIsActive(false)
        onKeyUp?.(e)
      }}
      {...buttonProps}
    >
      {variant === 'primary' ? (
        <HgPrimaryButtonContent uiState={uiState} iconProps={iconProps}>
          {children}
        </HgPrimaryButtonContent>
      ) : (
        <ButtonContent
          variant={variant}
          uiState={uiState}
          iconProps={iconProps}
          size={size}
        >
          {children}
        </ButtonContent>
      )}
    </Button>
  )
}

export type HgButtonLinkProps = LinkWrapperProps &
  HgButtonVariants &
  SizeDependentIconProps

export function HgButtonLink(props: HgButtonLinkProps) {
  const {
    className,
    variant = 'primary',
    size,
    children,
    uiState,
    iconProps,
    ...linkProps
  } = props

  const {button} = hgButtonVariants({variant, size, uiState})

  return (
    <Button
      asChild
      className={button({class: className})}
      data-button-variant={variant}
    >
      <LinkWrapper {...linkProps}>
        {variant === 'primary' ? (
          <HgPrimaryButtonContent uiState={uiState} iconProps={iconProps}>
            {children}
          </HgPrimaryButtonContent>
        ) : (
          <ButtonContent
            variant={variant}
            uiState={uiState}
            iconProps={iconProps}
            size={size}
          >
            {children}
          </ButtonContent>
        )}
      </LinkWrapper>
    </Button>
  )
}
