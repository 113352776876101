import {type IconProp} from '@fortawesome/fontawesome-svg-core'
import {
  faArrowDown as faArrowDownLight,
  faArrowDownRight as faArrowDownRightLight,
  faArrowDownToLine as faArrowDownToLineLight,
  faArrowDownWideShort as faArrowDownWideShortLight,
  faArrowLeft as faArrowLeftLight,
  faArrowProgress as faArrowProgressLight,
  faArrowRightArrowLeft as faArrowRightArrowLeftLight,
  faArrowRight as faArrowRightLight,
  faArrowRotateRight as faArrowRotateRightLight,
  faArrowUp as faArrowUpLight,
  faArrowUpRightDots as faArrowUpRightDotsLight,
  faArrowUpRight as faArrowUpRightLight,
  faArrowUpShortWide as faArrowUpShortWideLight,
  faArrowsRotate as faArrowsRotateLight,
  faAtom as faAtomLight,
  faAtomSimple as faAtomSimpleLight,
  faBank as faBankLight,
  faBars as faBarsLight,
  faBell as faBellLight,
  faBookOpenCover as faBookOpenCoverLight,
  faBrainCircuit as faBrainCircuitLight,
  faBrowsers as faBrowsersLight,
  faBullseyeArrow as faBullseyeArrowLight,
  faChartWaterfall as faChartWaterfallLight,
  faCheckCircle as faCheckCircleLight,
  faCheck as faCheckLight,
  faChevronDown as faChevronDownLight,
  faChevronLeft as faChevronLeftLight,
  faChevronRight as faChevronRightLight,
  faChevronUp as faChevronUpLight,
  faCircleExclamation as faCircleExclamationLight,
  faCircleInfo as faCircleInfoLight,
  faCircle as faCircleLight,
  faCircleNodes as faCircleNodesLight,
  faCircleXmark as faCircleXmarkLight,
  faCopy as faCopyLight,
  faCreditCard as faCreditCardLight,
  faDash as faDashLight,
  faEdit as faEditLight,
  faEllipsis as faEllipsisLight,
  faEnvelopeOpenText as faEnvelopeOpenTextLight,
  faFileCheck as faFileCheckLight,
  faFileInvoiceDollar as faFileInvoiceDollarLight,
  faFileInvoice as faFileInvoiceLight,
  faFileUser as faFileUserLight,
  faFileZipper as faFileZipperLight,
  faGear as faGearLight,
  faGears as faGearsLight,
  faGlobe as faGlobeLight,
  faHandHoldingMagic as faHandHoldingMagicLight,
  faHandSparkles as faHandSparklesLight,
  faHandshake as faHandshakeLight,
  faHeart as faHeartLight,
  faImage as faImageLight,
  faInfinity as faInfinityLight,
  faInfoCircle as faInfoCircleLight,
  faLayerGroup as faLayerGroupLight,
  faLightbulb as faLightbulbLight,
  faLink as faLinkLight,
  faMagnifyingGlassDollar as faMagnifyingGlassDollarLight,
  faMagnifyingGlass as faMagnifyingGlassLight,
  faMessagesQuestion as faMessagesQuestionLight,
  faMinus as faMinusLight,
  faMoneyBill as faMoneyBillLight,
  faMoneyBillTransfer as faMoneyBillTransferLight,
  faPaperPlane as faPaperPlaneLight,
  faPaste as faPasteLight,
  faPlus as faPlusLight,
  faReceipt as faReceiptLight,
  faRectangleList as faRectangleListLight,
  faScrewdriverWrench as faScrewdriverWrenchLight,
  faShield as faShieldLight,
  faSparkles as faSparklesLight,
  faSpinnerThird as faSpinnerThirdLight,
  faTable as faTableLight,
  faUserCheck as faUserCheckLight,
  faUserGroupSimple as faUserGroupSimpleLight,
  faUser as faUserLight,
  faUserTie as faUserTieLight,
  faUsers as faUsersLight,
  faVolume as faVolumeLight,
  faVolumeSlash as faVolumeSlashLight,
  faXmark as faXmarkLight,
} from '@fortawesome/pro-light-svg-icons'
import {
  faArrowDown as faArrowDownRegular,
  faArrowDownRight as faArrowDownRightRegular,
  faArrowDownToLine as faArrowDownToLineRegular,
  faArrowDownWideShort as faArrowDownWideShortRegular,
  faArrowLeft as faArrowLeftRegular,
  faArrowProgress as faArrowProgressRegular,
  faArrowRightArrowLeft as faArrowRightArrowLeftRegular,
  faArrowRight as faArrowRightRegular,
  faArrowRotateRight as faArrowRotateRightRegular,
  faArrowUp as faArrowUpRegular,
  faArrowUpRightDots as faArrowUpRightDotsRegular,
  faArrowUpRight as faArrowUpRightRegular,
  faArrowUpShortWide as faArrowUpShortWideRegular,
  faArrowsRotate as faArrowsRotateRegular,
  faAtom as faAtomRegular,
  faAtomSimple as faAtomSimpleRegular,
  faBank as faBankRegular,
  faBars as faBarsRegular,
  faBell as faBellRegular,
  faBookOpenCover as faBookOpenCoverRegular,
  faBrainCircuit as faBrainCircuitRegular,
  faBrowsers as faBrowsersRegular,
  faBullseyeArrow as faBullseyeArrowRegular,
  faChartWaterfall as faChartWaterfallRegular,
  faCheckCircle as faCheckCircleRegular,
  faCheck as faCheckRegular,
  faChevronDown as faChevronDownRegular,
  faChevronLeft as faChevronLeftRegular,
  faChevronRight as faChevronRightRegular,
  faChevronUp as faChevronUpRegular,
  faCircleExclamation as faCircleExclamationRegular,
  faCircleInfo as faCircleInfoRegular,
  faCircleNodes as faCircleNodesRegular,
  faCircle as faCircleRegular,
  faCircleXmark as faCircleXmarkRegular,
  faCopy as faCopyRegular,
  faCreditCard as faCreditCardRegular,
  faDash as faDashRegular,
  faEdit as faEditRegular,
  faEllipsis as faEllipsisRegular,
  faEnvelopeOpenText as faEnvelopeOpenTextRegular,
  faFileCheck as faFileCheckRegular,
  faFileInvoiceDollar as faFileInvoiceDollarRegular,
  faFileInvoice as faFileInvoiceRegular,
  faFileUser as faFileUserRegular,
  faFileZipper as faFileZipperRegular,
  faGear as faGearRegular,
  faGears as faGearsRegular,
  faGlobe as faGlobeRegular,
  faHandHoldingMagic as faHandHoldingMagicRegular,
  faHandSparkles as faHandSparklesRegular,
  faHandshake as faHandshakeRegular,
  faHeart as faHeartRegular,
  faImage as faImageRegular,
  faInfinity as faInfinityRegular,
  faInfoCircle as faInfoCircleRegular,
  faLayerGroup as faLayerGroupRegular,
  faLightbulb as faLightbulbRegular,
  faLink as faLinkRegular,
  faMagnifyingGlassDollar as faMagnifyingGlassDollarRegular,
  faMagnifyingGlass as faMagnifyingGlassRegular,
  faMessagesQuestion as faMessagesQuestionRegular,
  faMinus as faMinusRegular,
  faMoneyBill as faMoneyBillRegular,
  faMoneyBillTransfer as faMoneyBillTransferRegular,
  faPaperPlane as faPaperPlaneRegular,
  faPaste as faPasteRegular,
  faPlus as faPlusRegular,
  faReceipt as faReceiptRegular,
  faRectangleList as faRectangleListRegular,
  faScrewdriverWrench as faScrewdriverWrenchRegular,
  faShield as faShieldRegular,
  faSparkles as faSparklesRegular,
  faSpinnerThird as faSpinnerThirdRegular,
  faTable as faTableRegular,
  faUserCheck as faUserCheckRegular,
  faUserGroupSimple as faUserGroupSimpleRegular,
  faUser as faUserRegular,
  faUserTie as faUserTieRegular,
  faUsers as faUsersRegular,
  faVolume as faVolumeRegular,
  faVolumeSlash as faVolumeSlashRegular,
  faXmark as faXmarkRegular,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faPause as faPauseHeavy,
  faPlay as faPlayHeavy,
} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {tv} from 'tailwind-variants'
import {cn, unreachableCase} from '~/design-system/utils'
import Placeholder from './_icons/placeholder.svg'
import SortDown from './_icons/sort-down.svg'
import SortUp from './_icons/sort-up.svg'

type HgIconSize = 'small' | 'regular' | 'large'

export const HgCustomIcons = ['placeholder', 'sort-down', 'sort-up'] as const

export const HgFaIcons = [
  'arrow-down',
  'arrow-left',
  'arrow-right',
  'arrow-up',
  'arrow-down-right-angle',
  'arrow-up-right-angle',
  'audio-off',
  'audio-on',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'checkmark',
  'copy',
  'download',
  'edit',
  'error',
  'info',
  'link',
  'loading',
  'menu',
  'minus',
  'paste',
  'pause',
  'play',
  'plus',
  'search',
  'settings',
  'success',
  'user',
  'xmark',
  'xmark-circle',
  'arrow-right-arrow-left',
  'bank',
  'credit-card',
  'chart-waterfall',
  'gears',
  'money-bill',
  'sparkles',
  'circle-nodes',
  'user-check',
  'invoice',
  'globe',
  'magnifying-glass-dollar',
  'stack',
  'arrow-up-short-wide',
  'arrow-down-wide-short',
  'user-group-simple',
  'screwdriver-wrench',
  'receipt',
  'bell',
  'bullseye-arrow',
  'browsers',
  'file-user',
  'table',
  'file-zipper',
  'paper-plane',
  'users',
  'rectangle-list',
  'arrows-rotate',
  'file-check',
  'info-circle',
  'book-open-cover',
  'file-invoice',
  'handshake',
  'lightbulb',
  'messages-question',
  'shield',
  'dash',
  'brain',
  'heart',
  'hand-sparkles',
  'pathway',
  'wise-hand',
  'growth-chart',
  'infinity',
  'arrow-rotate-right',
  'image',
  'ellipsis',
  'atom',
  'atom-simple',
  'envelope-open-text',
  'money-bill-transfer',
  'user-tie',
] as const

type HgFaIconType = (typeof HgFaIcons)[number]

type HgFaIconProps = {
  className?: string
  iconType: HgFaIconType
  size: HgIconSize
}

type HgCustomIconType = (typeof HgCustomIcons)[number]

export type HgIconType = HgFaIconType | HgCustomIconType

type HgIconProps = {
  className?: string
  iconType: HgIconType
  size: HgIconSize
}

const SANCTIONED_FA_ICON_MAP: Record<
  // 'loading' is a special case of 2 FA icons being combined rather than the 1:1 mapping of the other FA icons
  Exclude<HgFaIconType, 'loading'>,
  Record<HgIconSize, IconProp>
> = {
  // "UI" icons
  'arrow-down': {
    small: faArrowDownRegular,
    regular: faArrowDownRegular,
    large: faArrowDownLight,
  },
  'arrow-left': {
    small: faArrowLeftRegular,
    regular: faArrowLeftRegular,
    large: faArrowLeftLight,
  },
  'arrow-right': {
    small: faArrowRightRegular,
    regular: faArrowRightRegular,
    large: faArrowRightLight,
  },
  'arrow-up': {
    small: faArrowUpRegular,
    regular: faArrowUpRegular,
    large: faArrowUpLight,
  },
  'arrow-down-right-angle': {
    small: faArrowDownRightRegular,
    regular: faArrowDownRightRegular,
    large: faArrowDownRightLight,
  },
  'arrow-up-right-angle': {
    small: faArrowUpRightRegular,
    regular: faArrowUpRightRegular,
    large: faArrowUpRightLight,
  },
  'audio-off': {
    small: faVolumeSlashRegular,
    regular: faVolumeSlashRegular,
    large: faVolumeSlashLight,
  },
  'audio-on': {
    small: faVolumeRegular,
    regular: faVolumeRegular,
    large: faVolumeLight,
  },
  'chevron-down': {
    small: faChevronDownRegular,
    regular: faChevronDownRegular,
    large: faChevronDownLight,
  },
  'chevron-left': {
    small: faChevronLeftRegular,
    regular: faChevronLeftRegular,
    large: faChevronLeftLight,
  },
  'chevron-right': {
    small: faChevronRightRegular,
    regular: faChevronRightRegular,
    large: faChevronRightLight,
  },
  'chevron-up': {
    small: faChevronUpRegular,
    regular: faChevronUpRegular,
    large: faChevronUpLight,
  },
  'checkmark': {
    small: faCheckRegular,
    regular: faCheckRegular,
    large: faCheckLight,
  },
  'copy': {
    small: faCopyRegular,
    regular: faCopyRegular,
    large: faCopyLight,
  },
  'download': {
    small: faArrowDownToLineRegular,
    regular: faArrowDownToLineRegular,
    large: faArrowDownToLineLight,
  },
  'edit': {
    small: faEditRegular,
    regular: faEditRegular,
    large: faEditLight,
  },
  'ellipsis': {
    small: faEllipsisRegular,
    regular: faEllipsisRegular,
    large: faEllipsisLight,
  },
  'error': {
    small: faCircleExclamationRegular,
    regular: faCircleExclamationRegular,
    large: faCircleExclamationLight,
  },
  'info': {
    small: faCircleInfoRegular,
    regular: faCircleInfoRegular,
    large: faCircleInfoLight,
  },
  'link': {
    small: faLinkRegular,
    regular: faLinkRegular,
    large: faLinkLight,
  },
  'menu': {
    small: faBarsRegular,
    regular: faBarsRegular,
    large: faBarsLight,
  },
  'minus': {
    small: faMinusRegular,
    regular: faMinusRegular,
    large: faMinusLight,
  },
  'paste': {
    small: faPasteRegular,
    regular: faPasteRegular,
    large: faPasteLight,
  },
  'pause': {
    small: faPauseHeavy,
    regular: faPauseHeavy,
    large: faPauseHeavy,
  },
  'play': {
    small: faPlayHeavy,
    regular: faPlayHeavy,
    large: faPlayHeavy,
  },
  'plus': {
    small: faPlusRegular,
    regular: faPlusRegular,
    large: faPlusLight,
  },
  'search': {
    small: faMagnifyingGlassRegular,
    regular: faMagnifyingGlassRegular,
    large: faMagnifyingGlassLight,
  },
  'settings': {
    small: faGearRegular,
    regular: faGearRegular,
    large: faGearLight,
  },
  'success': {
    small: faCheckCircleRegular,
    regular: faCheckCircleRegular,
    large: faCheckCircleLight,
  },
  'user': {
    small: faUserRegular,
    regular: faUserRegular,
    large: faUserLight,
  },
  'xmark': {
    small: faXmarkRegular,
    regular: faXmarkRegular,
    large: faXmarkLight,
  },
  'xmark-circle': {
    small: faCircleXmarkRegular,
    regular: faCircleXmarkRegular,
    large: faCircleXmarkLight,
  },
  // "Decorative" icons
  'arrow-right-arrow-left': {
    small: faArrowRightArrowLeftRegular,
    regular: faArrowRightArrowLeftRegular,
    large: faArrowRightArrowLeftLight,
  },
  'bank': {
    small: faBankRegular,
    regular: faBankRegular,
    large: faBankLight,
  },
  'chart-waterfall': {
    small: faChartWaterfallRegular,
    regular: faChartWaterfallRegular,
    large: faChartWaterfallLight,
  },
  'credit-card': {
    small: faCreditCardRegular,
    regular: faCreditCardRegular,
    large: faCreditCardLight,
  },
  'gears': {
    small: faGearsRegular,
    regular: faGearsRegular,
    large: faGearsLight,
  },
  'money-bill': {
    small: faMoneyBillRegular,
    regular: faMoneyBillRegular,
    large: faMoneyBillLight,
  },
  'sparkles': {
    small: faSparklesRegular,
    regular: faSparklesRegular,
    large: faSparklesLight,
  },
  'circle-nodes': {
    small: faCircleNodesRegular,
    regular: faCircleNodesRegular,
    large: faCircleNodesLight,
  },
  'user-check': {
    small: faUserCheckRegular,
    regular: faUserCheckRegular,
    large: faUserCheckLight,
  },
  'invoice': {
    small: faFileInvoiceDollarRegular,
    regular: faFileInvoiceDollarRegular,
    large: faFileInvoiceDollarLight,
  },
  'globe': {small: faGlobeRegular, regular: faGlobeRegular, large: faGlobeLight},
  'magnifying-glass-dollar': {
    small: faMagnifyingGlassDollarRegular,
    regular: faMagnifyingGlassDollarRegular,
    large: faMagnifyingGlassDollarLight,
  },
  'stack': {
    small: faLayerGroupRegular,
    regular: faLayerGroupRegular,
    large: faLayerGroupLight,
  },
  'arrow-up-short-wide': {
    small: faArrowUpShortWideRegular,
    regular: faArrowUpShortWideRegular,
    large: faArrowUpShortWideLight,
  },
  'arrow-down-wide-short': {
    small: faArrowDownWideShortRegular,
    regular: faArrowDownWideShortRegular,
    large: faArrowDownWideShortLight,
  },
  'user-group-simple': {
    small: faUserGroupSimpleRegular,
    regular: faUserGroupSimpleRegular,
    large: faUserGroupSimpleLight,
  },
  'screwdriver-wrench': {
    small: faScrewdriverWrenchRegular,
    regular: faScrewdriverWrenchRegular,
    large: faScrewdriverWrenchLight,
  },
  'receipt': {
    small: faReceiptRegular,
    regular: faReceiptRegular,
    large: faReceiptLight,
  },
  'bell': {
    small: faBellRegular,
    regular: faBellRegular,
    large: faBellLight,
  },
  'bullseye-arrow': {
    small: faBullseyeArrowRegular,
    regular: faBullseyeArrowRegular,
    large: faBullseyeArrowLight,
  },
  'browsers': {
    small: faBrowsersRegular,
    regular: faBrowsersRegular,
    large: faBrowsersLight,
  },
  'file-user': {
    small: faFileUserRegular,
    regular: faFileUserRegular,
    large: faFileUserLight,
  },
  'table': {
    small: faTableRegular,
    regular: faTableRegular,
    large: faTableLight,
  },
  'file-zipper': {
    small: faFileZipperRegular,
    regular: faFileZipperRegular,
    large: faFileZipperLight,
  },
  'paper-plane': {
    small: faPaperPlaneRegular,
    regular: faPaperPlaneRegular,
    large: faPaperPlaneLight,
  },
  'users': {
    small: faUsersRegular,
    regular: faUsersRegular,
    large: faUsersLight,
  },
  'rectangle-list': {
    small: faRectangleListRegular,
    regular: faRectangleListRegular,
    large: faRectangleListLight,
  },
  'arrows-rotate': {
    small: faArrowsRotateRegular,
    regular: faArrowsRotateRegular,
    large: faArrowsRotateLight,
  },
  'file-check': {
    small: faFileCheckRegular,
    regular: faFileCheckRegular,
    large: faFileCheckLight,
  },
  'info-circle': {
    small: faInfoCircleRegular,
    regular: faInfoCircleRegular,
    large: faInfoCircleLight,
  },
  'book-open-cover': {
    small: faBookOpenCoverRegular,
    regular: faBookOpenCoverRegular,
    large: faBookOpenCoverLight,
  },
  'file-invoice': {
    small: faFileInvoiceRegular,
    regular: faFileInvoiceRegular,
    large: faFileInvoiceLight,
  },
  'handshake': {
    small: faHandshakeRegular,
    regular: faHandshakeRegular,
    large: faHandshakeLight,
  },
  'lightbulb': {
    small: faLightbulbRegular,
    regular: faLightbulbRegular,
    large: faLightbulbLight,
  },
  'messages-question': {
    small: faMessagesQuestionRegular,
    regular: faMessagesQuestionRegular,
    large: faMessagesQuestionLight,
  },
  'shield': {
    small: faShieldRegular,
    regular: faShieldRegular,
    large: faShieldLight,
  },
  'dash': {
    small: faDashRegular,
    regular: faDashRegular,
    large: faDashLight,
  },
  'brain': {
    small: faBrainCircuitRegular,
    regular: faBrainCircuitRegular,
    large: faBrainCircuitLight,
  },
  'heart': {
    small: faHeartRegular,
    regular: faHeartRegular,
    large: faHeartLight,
  },
  'hand-sparkles': {
    small: faHandSparklesRegular,
    regular: faHandSparklesRegular,
    large: faHandSparklesLight,
  },
  'pathway': {
    small: faArrowProgressRegular,
    regular: faArrowProgressRegular,
    large: faArrowProgressLight,
  },
  'wise-hand': {
    small: faHandHoldingMagicRegular,
    regular: faHandHoldingMagicRegular,
    large: faHandHoldingMagicLight,
  },
  'growth-chart': {
    small: faArrowUpRightDotsRegular,
    regular: faArrowUpRightDotsRegular,
    large: faArrowUpRightDotsLight,
  },
  'infinity': {
    small: faInfinityRegular,
    regular: faInfinityRegular,
    large: faInfinityLight,
  },
  'arrow-rotate-right': {
    small: faArrowRotateRightRegular,
    regular: faArrowRotateRightRegular,
    large: faArrowRotateRightLight,
  },
  'image': {
    small: faImageRegular,
    regular: faImageRegular,
    large: faImageLight,
  },
  'atom': {
    small: faAtomRegular,
    regular: faAtomRegular,
    large: faAtomLight,
  },
  'atom-simple': {
    small: faAtomSimpleRegular,
    regular: faAtomSimpleRegular,
    large: faAtomSimpleLight,
  },
  'envelope-open-text': {
    small: faEnvelopeOpenTextRegular,
    regular: faEnvelopeOpenTextRegular,
    large: faEnvelopeOpenTextLight,
  },
  'money-bill-transfer': {
    small: faMoneyBillTransferRegular,
    regular: faMoneyBillTransferRegular,
    large: faMoneyBillTransferLight,
  },
  'user-tie': {
    small: faUserTieRegular,
    regular: faUserTieRegular,
    large: faUserTieLight,
  },
} as const

type FaIconSize = 'sm' | '1x' | 'lg'

const LoadingFaIcon = ({size}: {size: FaIconSize}) => {
  return (
    <div className="grid">
      <FontAwesomeIcon
        size={size}
        className="col-start-1 col-end-auto row-start-1 row-end-auto text-inherit opacity-25"
        icon={size === 'lg' ? faCircleLight : faCircleRegular}
      />
      <FontAwesomeIcon
        size={size}
        className="z-1 col-start-1 col-end-auto row-start-1 row-end-auto animate-spin text-inherit"
        icon={size === 'lg' ? faSpinnerThirdLight : faSpinnerThirdRegular}
      />
    </div>
  )
}

const HgFaIcon = ({className, iconType, size}: HgFaIconProps) => {
  // For FA icons, `sm` = .875rem, `1x` = 1rem, `xl` = 1.5rem
  const getFaIconSize = () => {
    switch (size) {
      case 'small':
        return 'sm'
      case 'large':
        return 'lg'
      default:
        return '1x'
    }
  }

  if (iconType === 'loading') {
    return <LoadingFaIcon size={getFaIconSize()} />
  }

  return (
    <FontAwesomeIcon
      size={getFaIconSize()}
      className={cn('text-inherit', className)}
      icon={SANCTIONED_FA_ICON_MAP[iconType]?.[size]}
    />
  )
}

function isHgFaIcon(iconType: HgIconType): iconType is HgFaIconType {
  return HgFaIcons.includes(iconType as HgFaIconType)
}

function isHgCustomIcon(iconType: HgIconType): iconType is HgCustomIconType {
  return HgCustomIcons.includes(iconType as HgCustomIconType)
}

const iconSize = tv({
  base: 'inline-block min-h-fit min-w-fit',
  variants: {
    size: {
      small: 'h-[0.875rem]',
      regular: 'h-[1rem]',
      large: 'h-[1.125rem]',
    },
    placeholder: {
      true: 'fill-none text-inherit',
    },
    sortIcon: {
      true: 'text-inherit',
    },
  },
  compoundVariants: [
    {sortIcon: true, size: 'small', class: 'h-[0.329rem] w-full'},
    {sortIcon: true, size: 'regular', class: 'h-[0.376rem] w-full'},
    {sortIcon: true, size: 'large', class: 'h-[0.429rem] w-full'},
  ],
})

const HgIcon = ({className, iconType, size}: HgIconProps) => {
  if (isHgFaIcon(iconType)) {
    return (
      <HgFaIcon
        className={cn(className, iconType === 'play' && 'relative -right-1')}
        iconType={iconType}
        size={size}
      />
    )
  }

  if (isHgCustomIcon(iconType)) {
    switch (iconType) {
      case 'placeholder':
        return (
          <Placeholder
            className={iconSize({size, placeholder: true, class: className})}
          />
        )
      case 'sort-up':
        return (
          <SortUp className={iconSize({size, sortIcon: true, class: className})} />
        )
      case 'sort-down':
        return (
          <SortDown className={iconSize({size, sortIcon: true, class: className})} />
        )
      default:
        return null
    }
  }

  return unreachableCase(iconType)
}

export default HgIcon
