import {type ReactNode} from 'react'
import {tv, type VariantProps} from 'tailwind-variants'
import {type ExtractStrict} from 'type-zoo'

const testimonial = tv({
  base: 'flex flex-col space-y-s6',
  slots: {
    blockQuote: 'before:content-[open-quote] after:content-[close-quote]',
  },
  variants: {
    alignment: {
      left: {base: 'items-start text-left', blockQuote: '-indent-[0.6ch]'},
      center: 'items-center text-center',
    },
    topSpacing: {
      s0: 'pt-s0',
      s4: 'pt-s4',
      s9: 'pt-s9',
    },
    variant: {
      compact: {
        blockQuote: 'text-text-subdued arcadia-body-2',
      },
      default: {
        blockQuote: 'text-text-default arcadia-body-1',
      },
    },
  },
  defaultVariants: {
    alignment: 'left',
    topSpacing: 's0',
    variant: 'default',
  },
})

type HgTestimonialVariants = VariantProps<typeof testimonial>

type HgTestimonialBaseProps = {
  quote: ReactNode
  firstName: ReactNode
  lastName: ReactNode
  company: ReactNode
  role: ReactNode
}

export type HgTestimonialDefaultProps = HgTestimonialBaseProps & {
  variant?: ExtractStrict<HgTestimonialVariants['variant'], 'default'>
  sector: ReactNode
  alignment?: HgTestimonialVariants['alignment']
  topSpacing?: Exclude<HgTestimonialVariants['topSpacing'], 's4'>
}

export type HgTestimonialCompactProps = HgTestimonialBaseProps & {
  variant: ExtractStrict<HgTestimonialVariants['variant'], 'compact'>
  topSpacing?: Exclude<HgTestimonialVariants['topSpacing'], 's9'>
}

export type HgTestimonialProps =
  | HgTestimonialDefaultProps
  | HgTestimonialCompactProps

const HgTestimonial = ({
  quote,
  firstName,
  lastName,
  company,
  role,
  variant = 'default',
  topSpacing = 's0',
  ...props
}: HgTestimonialProps) => {
  const isDefaultVariant = (
    spreadProps: typeof props,
    prop: keyof HgTestimonialDefaultProps
  ): spreadProps is HgTestimonialDefaultProps =>
    variant === 'default' && prop in spreadProps

  const alignment = isDefaultVariant(props, 'alignment') ? props.alignment : 'left'
  const sector = isDefaultVariant(props, 'sector') ? props.sector : undefined

  const {base, blockQuote} = testimonial({alignment, topSpacing, variant})

  return (
    <figure className={base()}>
      <blockquote className={blockQuote()}>{quote}</blockquote>
      <div className="flex flex-row items-center justify-center">
        <figcaption className="flex flex-row items-center">
          <div className="text-left">
            <p className="text-text-default arcadia-heading-3">
              {firstName} {lastName}
            </p>
            <p className="text-text-subdued arcadia-body-2">
              {role}
              {sector ? (
                ''
              ) : (
                <>
                  {', '}
                  {company}
                </>
              )}
            </p>
          </div>
          {sector && (
            <div className="ml-16 border-l-2 border-border-subdued pl-16 text-left">
              <p className="text-text-default arcadia-heading-3">{company}</p>
              <p className="text-text-subdued arcadia-body-2">{sector}</p>
            </div>
          )}
        </figcaption>
      </div>
    </figure>
  )
}

export default HgTestimonial
