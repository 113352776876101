'use client'
// eslint-disable-next-line no-restricted-imports
import * as TabsPrimitive from '@radix-ui/react-tabs'
import React, {createContext, useCallback, useEffect, useRef, useState} from 'react'
import {tv} from 'tailwind-variants'
import {useIsVisible} from '~/design-system/hooks/useIsVisible'
import {cn} from '~/design-system/utils'

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({className, ...props}, ref) => (
  <TabsPrimitive.List ref={ref} className={className} {...props} />
))
TabsList.displayName = TabsPrimitive.List.displayName

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({className, ...props}, ref) => (
  <TabsPrimitive.Trigger ref={ref} className={className} {...props} />
))
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({className, ...props}, ref) => (
  <TabsPrimitive.Content ref={ref} className={className} {...props} />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

export type Tab = {
  key: string
  label: React.ReactNode
  content: React.ReactNode
}

export type TabsProps = {
  tabs: Tab[]
  verticalTabs?: boolean
}

type TabsPrimitiveProps = TabsProps & {
  classNames?: {
    container?: string
    contentContainer?: string
    content?: string
    listWrapper?: string
    list?: string
    trigger?: string
    indicator?: string
    border?: string
  }
}

type TabsState = {
  currentTab: string
}

const TabsContext = createContext<TabsState>({} as TabsState)

const tabsList = tv({
  base: 'flex h-fit w-fit',
  variants: {
    orientation: {
      horizontal:
        'max-w-full overflow-x-auto before:pointer-events-none before:absolute before:left-0 before:top-0 before:z-[1] before:h-[calc(100%_-_11px)] before:w-40 before:opacity-0 after:pointer-events-none after:absolute after:right-0 after:top-0 after:z-[1] after:h-[calc(100%_-_11px)] after:w-40 after:opacity-0 sm:overflow-x-hidden',
      vertical: '',
    },
    showLeftGradient: {
      true: '',
    },
    showRightGradient: {
      true: '',
    },
  },
  compoundVariants: [
    {
      orientation: 'horizontal',
      showLeftGradient: true,
      class:
        'before:bg-gradient-to-r before:from-background-default before:opacity-100 before:transition-opacity',
    },
    {
      orientation: 'horizontal',
      showRightGradient: true,
      class:
        'after:bg-gradient-to-l after:from-background-default after:opacity-100 after:transition-opacity',
    },
  ],
})

function Tabs({tabs, classNames, verticalTabs = false}: TabsPrimitiveProps) {
  const currentTabRef = useRef<HTMLButtonElement | null>(null)
  const tabsListRef = useRef<HTMLDivElement | null>(null)
  const [tabIndicatorPosition, setTabIndicatorPosition] = useState(0)
  const [tabIndicatorLength, setTabIndicatorLength] = useState(0)
  const [currentTab, setCurrentTab] = useState(tabs[0].key)
  const [showLeftGradient, setShowLeftGradient] = useState(false)
  const [showRightGradient, setShowRightGradient] = useState(false)
  const isTabsListVisible = useIsVisible(tabsListRef, {rootMargin: '0px'})

  const updateIndicator = useCallback(() => {
    const currentTabElement = currentTabRef.current
    if (!currentTabElement) {
      return
    }
    setTabIndicatorPosition(
      verticalTabs ? currentTabElement.offsetTop : currentTabElement.offsetLeft
    )
    setTabIndicatorLength(
      verticalTabs ? currentTabElement.offsetHeight : currentTabElement.offsetWidth
    )
  }, [verticalTabs])

  const setTabGradients = useCallback(() => {
    if (!tabsListRef.current) return
    const {scrollLeft, scrollWidth, offsetWidth, clientWidth} = tabsListRef.current
    const isScrollable = scrollWidth > clientWidth
    const contentWidth = scrollWidth - offsetWidth

    if (isScrollable) {
      setShowRightGradient(true)
      setShowLeftGradient(true)

      if (scrollLeft === 0) {
        setShowLeftGradient(false)
      }
      if (contentWidth <= scrollLeft) {
        setShowRightGradient(false)
      }
    } else {
      setShowLeftGradient(false)
      setShowRightGradient(false)
    }
  }, [])

  useEffect(() => {
    if (!tabsListRef.current) return

    setTabGradients()

    const resizeObserver = new ResizeObserver(setTabGradients)
    resizeObserver.observe(tabsListRef.current)
    return () => {
      resizeObserver.disconnect()
    }
  }, [setTabGradients])

  useEffect(() => {
    updateIndicator()
  }, [currentTab, verticalTabs, updateIndicator])

  useEffect(() => {
    if (!currentTabRef.current) return
    const resizeObserver = new ResizeObserver(updateIndicator)
    resizeObserver.observe(currentTabRef.current)
    return () => {
      resizeObserver.disconnect()
    }
  }, [updateIndicator])

  useEffect(() => {
    if (!isTabsListVisible) return
    currentTabRef.current?.scrollIntoView({
      behavior: 'auto',
      block: 'nearest',
      inline: 'nearest',
    })
  }, [currentTab, isTabsListVisible])

  return (
    <TabsContext.Provider value={{currentTab}}>
      <TabsPrimitive.Root
        className={cn(
          'flex flex-col data-[orientation=vertical]:flex-row',
          classNames?.container
        )}
        defaultValue={tabs[0].key}
        onValueChange={value => {
          setCurrentTab(value)
        }}
        orientation={verticalTabs ? 'vertical' : 'horizontal'}
      >
        <TabsList className={cn(classNames?.listWrapper, 'group relative flex')}>
          <div
            className={tabsList({
              orientation: verticalTabs ? 'vertical' : 'horizontal',
              showLeftGradient,
              showRightGradient,
            })}
            onScroll={setTabGradients}
            ref={tabsListRef}
          >
            <div className="relative flex group-data-[orientation=vertical]:flex-col">
              <span
                className={cn(classNames?.indicator, 'chromatic-ignore')}
                style={
                  verticalTabs
                    ? {
                        transform: `translateY(${tabIndicatorPosition}px)`,
                        height: tabIndicatorLength,
                      }
                    : {
                        transform: `translateX(${tabIndicatorPosition}px)`,
                        width: tabIndicatorLength,
                      }
                }
              />
              {tabs.map(tab => (
                <TabsTrigger
                  value={tab.key}
                  className={cn(
                    classNames?.trigger,
                    'group/trigger focus-visible:outline-none'
                  )}
                  ref={currentTab === tab.key ? currentTabRef : undefined}
                  key={`trigger-${tab.key}`}
                >
                  <span className="text-nowrap rounded-sm outline-2 outline-offset-4 outline-border-focus group-focus-visible/trigger:outline">
                    {tab.label}
                  </span>
                </TabsTrigger>
              ))}
              <div className={classNames?.border} />
            </div>
          </div>
        </TabsList>
        <div className={classNames?.contentContainer}>
          {tabs.map(tab => (
            <TabsContent
              value={tab.key}
              key={`content-${tab.key}`}
              className={cn(
                classNames?.content,
                'data-[state=active]:visible data-[state=inactive]:invisible'
              )}
              forceMount
              aria-hidden={currentTab !== tab.key}
            >
              {tab.content}
            </TabsContent>
          ))}
        </div>
      </TabsPrimitive.Root>
    </TabsContext.Provider>
  )
}

export function useTabsContext() {
  const context = React.useContext(TabsContext)

  if (!context) {
    throw new Error('useTabsContext must be used within <Tabs> component')
  }

  return context
}

export default Tabs
