import {Slot} from '@radix-ui/react-slot'
import {grid, gridItem} from '~/design-system/foundations'
import {InfiniteMovingCards} from '~/design-system/foundations/InfiniteMovingCards'

export type Logo = {
  logo: React.ReactNode
  companyName: string
}

export type HgLogoMashProps = {
  logos: [Logo, Logo, Logo, Logo, ...Logo[]]
  title?: string | React.ReactNode
}

export default function HgLogoMash(props: HgLogoMashProps) {
  const {logos, title} = props

  return (
    <div className="w-full">
      <div className={grid({className: 'mx-auto'})}>
        {title && (
          <p
            className={gridItem({
              size: 'heading',
              className: 'mt-s9 text-center text-text-default arcadia-heading-2',
            })}
          >
            {title}
          </p>
        )}
        <InfiniteMovingCards
          items={logos.map((logo, idx) => (
            <Slot key={idx} aria-label={logo.companyName}>
              {logo.logo}
            </Slot>
          ))}
          classNames={{
            container: gridItem({size: 'max', className: 'mt-s6'}),
            scrollerItem:
              'h-48 w-auto text-icon-default md:h-64 3xl:h-80 [&>*]:h-full [&_*]:!fill-icon-default [&_svg]:h-full',
          }}
          itemScrollDurationSeconds={2.5}
          itemGap="s10"
        />
      </div>
    </div>
  )
}
