import {HgIcon, type HgIconType, HgToolTip} from '~/design-system/hg/components'
import {cn} from '~/design-system/utils'

export type HgIndexTableTitleCellContentProps = {
  title?: React.ReactNode
  body?: React.ReactNode
  hint?: React.ReactNode
  iconType?: HgIconType
  className?: string
}

const TooltipIcon = <HgIcon size="small" iconType="info-circle" />

export const HgIndexTableTitleCell = ({
  title,
  body,
  hint,
  iconType,
  className,
}: HgIndexTableTitleCellContentProps) => {
  if (iconType) {
    return (
      <div className={cn('flex h-full w-full items-center', className)}>
        <HgIcon
          size="small"
          iconType={iconType}
          className="text-icon-default md:hidden"
        />
        <HgIcon
          size="regular"
          iconType={iconType}
          className="hidden text-icon-default md:block lg:hidden"
        />
        <HgIcon
          size="large"
          iconType={iconType}
          className="hidden text-icon-default lg:block"
        />
      </div>
    )
  }

  return (
    <div
      className={cn(
        'flex h-full w-full flex-row items-center space-x-s2',
        className
      )}
    >
      <div className="flex flex-col items-start justify-items-center">
        {title && (
          <p className="flex flex-1 items-center text-text-default arcadia-heading-3">
            {title}
            {hint && <HgToolTip trigger={TooltipIcon} content={hint} />}
          </p>
        )}
        {body && (
          <p className="flex flex-1 items-center text-text-default arcadia-body-2">
            {body}
          </p>
        )}
      </div>
    </div>
  )
}
