'use client'

import {
  cloneElement,
  useCallback,
  useEffect,
  useRef,
  useState,
  type ReactElement,
} from 'react'
import {HgButton, HgThemeSelector} from '~/design-system/hg/components'
import {type Theme} from '~/design-system/hg/tokens/colors'
import {useIsVisible} from '~/design-system/hooks/useIsVisible'
import {usePrefersReducedMotion} from '~/hooks/useMediaQuery'
import {type AspectRatio} from '../HgAspectRatio'
import './styles.css'

const CIRCLE_LOADER_SIZE = 40
const CIRCLE_LOADER_STROKE_WIDTH = 1
const CIRCLE_LOADER_RADIUS = (CIRCLE_LOADER_SIZE - CIRCLE_LOADER_STROKE_WIDTH) / 2
const CIRCLE_LOADER_CIRCUMFERENCE = CIRCLE_LOADER_RADIUS * 2 * Math.PI

export type HgAnimationProps = {
  aspectRatio: AspectRatio
  poster?: string
  controlsTheme?: Theme
  showVolumeButton?: boolean
  asset: ReactElement
  blurDataURL?: string
  onPlayError: (error: unknown) => void
  preload?: 'auto' | 'metadata' | 'none'
}

const aspectRatioMap: Record<AspectRatio, string> = {
  '16:5': '16/5',
  '16:9': '16/9',
  '4:5': '4/5',
  '3:2': '3/2',
  '1:1': '1/1',
  '2:1': '2/1',
}

const HgAnimation = ({
  aspectRatio,
  poster,
  controlsTheme = 'neutral',
  showVolumeButton = false,
  asset,
  blurDataURL,
  onPlayError = () => {},
  preload = 'metadata',
}: HgAnimationProps) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [isMuted, setIsMuted] = useState(true)
  const [isEnded, setIsEnded] = useState(false)
  const [duration, setDuration] = useState(0)
  const videoRef = useRef<HTMLVideoElement>(null)
  const isVisible = useIsVisible(videoRef, {rootMargin: '0px', threshold: 0.5})
  const shouldDisableAnimation = usePrefersReducedMotion()

  const pause = () => {
    videoRef.current?.pause()
    setIsPlaying(false)
  }

  const play = useCallback(() => {
    if (!videoRef.current) {
      return
    }
    // In order to play on safari w/o user interaction, autoplay must be true. However, we don't
    // want to play animation until it is in view, hence why we wait to set autoplay attribute
    videoRef.current.autoplay = true
    videoRef.current
      .play()
      .then(() => {
        setIsPlaying(true)
      })
      .catch(err => {
        onPlayError(err)
      })
  }, [onPlayError])

  const handlePlayPauseClick = () => {
    if (isPlaying) {
      pause()
    } else {
      play()
      setIsEnded(false)
    }
  }

  const handleVideoEnded = () => {
    setIsPlaying(false)
    setIsEnded(true)
  }

  const handleLoadedMetadata = () => {
    setDuration(Math.round((videoRef.current?.duration || 0) * 1000))
  }

  useEffect(() => {
    if (shouldDisableAnimation) {
      return
    }
    if (isVisible && !isEnded) {
      play()
    }
    if (!isVisible) {
      pause()
    }
  }, [shouldDisableAnimation, isVisible, isEnded, play])

  return (
    <>
      <div className="col-start-1 col-end-auto row-start-1 row-end-auto">
        {cloneElement(asset, {
          ref: videoRef,
          style: {
            aspectRatio: aspectRatioMap[aspectRatio],
            minHeight: '100%',
            minWidth: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          },
          playsInline: true,
          poster,
          preload,
          blurDataURL,
          muted: isMuted,
          onEnded: handleVideoEnded,
          onLoadedMetadata: handleLoadedMetadata,
        })}
      </div>
      <HgThemeSelector theme={controlsTheme}>
        <div className="col-start-1 col-end-auto row-start-1 row-end-auto flex items-end p-s3 [&_svg]:text-icon-default">
          <div className="grid">
            <HgButton
              variant="secondary"
              size="icon"
              className="col-start-1 col-end-auto row-start-1 row-end-auto"
              iconProps={{iconType: isPlaying ? 'pause' : 'play'}}
              onClick={handlePlayPauseClick}
              aria-label={isPlaying ? 'Pause' : 'Play'}
            />
            {!!duration && (
              <svg
                width={CIRCLE_LOADER_SIZE}
                height={CIRCLE_LOADER_SIZE}
                className="pointer-events-none relative col-start-1 col-end-auto row-start-1 row-end-auto rounded-full"
              >
                <circle
                  className={`animate-[circleLoaderFill_var(--playback-duration)_linear]`}
                  style={
                    {
                      'strokeDasharray': `${CIRCLE_LOADER_CIRCUMFERENCE} ${CIRCLE_LOADER_CIRCUMFERENCE}`,
                      '--playback-duration': `${duration}ms`,
                      'animationPlayState': isPlaying ? 'running' : 'paused',
                      'animationName': isEnded ? 'none' : '',
                    } as React.CSSProperties
                  }
                  stroke="currentColor"
                  strokeWidth={CIRCLE_LOADER_STROKE_WIDTH}
                  fill="transparent"
                  r={CIRCLE_LOADER_RADIUS}
                  cx={CIRCLE_LOADER_SIZE / 2}
                  cy={CIRCLE_LOADER_SIZE / 2}
                  transform={`rotate(-90 ${CIRCLE_LOADER_SIZE / 2} ${
                    CIRCLE_LOADER_SIZE / 2
                  })`}
                />
              </svg>
            )}
          </div>
          {showVolumeButton && (
            <HgButton
              variant="secondary"
              size="icon"
              className="ms-s3"
              iconProps={{iconType: isMuted ? 'audio-off' : 'audio-on'}}
              onClick={() => {
                setIsMuted(!isMuted)
              }}
              aria-label={isMuted ? 'unmute' : 'mute'}
            />
          )}
        </div>
      </HgThemeSelector>
    </>
  )
}

export default HgAnimation
