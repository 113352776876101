export const typographyUtilities = {
  '.arcadia-display-1': {
    '@apply font-display font-320 tracking-normal leading-110% text-5xl md:text-6xl lg:text-7xl xl:text-8xl 2xl:text-9xl 3xl:text-10xl':
      {},
  },
  '.arcadia-display-2': {
    '@apply font-display font-320 tracking-normal leading-110% ~text-4xl/10xl': {},
  },
  '.arcadia-heading-1': {
    '@apply font-display font-320 tracking-normal leading-115% md:leading-110% text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl 3xl:text-7xl':
      {},
  },
  '.arcadia-heading-2': {
    '@apply font-display font-360 leading-135% md:leading-120% 2xl:leading-115% text-lg md:text-xl lg:text-2xl 2xl:text-3xl tracking-2% md:tracking-1% lg:tracking-normal':
      {},
  },
  '.arcadia-heading-3': {
    '@apply font-sans font-480 tracking-normal leading-135% text-sm md:text-base lg:text-md':
      {},
  },
  '.arcadia-body-1': {
    '@apply font-sans font-360 tracking-normal leading-120% text-md md:text-lg lg:text-xl':
      {},
  },
  '.arcadia-body-2': {
    '@apply font-sans font-360 tracking-normal leading-135% text-sm md:text-base lg:text-md tracking-normal':
      {},
  },
  '.arcadia-body-3': {
    '@apply font-sans font-400 leading-140% text-sm tracking-1%': {},
  },
  '.arcadia-body-4': {
    '@apply font-sans font-400 leading-140% text-xxs md:text-xs tracking-2%': {},
  },
  '.arcadia-ui-1': {
    '@apply font-sans font-420 leading-none text-base tracking-normal': {},
  },
  '.arcadia-ui-2': {
    '@apply font-sans font-440 text-sm leading-none tracking-normal': {},
  },
  '.arcadia-ui-3': {
    '@apply font-sans font-480 text-xxs leading-none tracking-normal': {},
  },
}
