import {type HgButtonLinkProps, HgButtonLink} from '../HgButton'
import HgIcon, {type HgIconType} from '../HgIcon'

type CompanyListItemProps = {
  companyName: React.ReactNode
  companyLogo: React.ReactNode
}

type GenericListItemProps = {
  iconType?: HgIconType
  body: React.ReactNode
  caption?: React.ReactNode
}

type PricingCardListItemProps = CompanyListItemProps | GenericListItemProps

export type PricingCardListProps = {
  heading: React.ReactNode
  listItems: PricingCardListItemProps[]
}

export type PricingCardHeadingProps = {
  planName: React.ReactNode
  planIcon?: React.ReactNode
  pricePerMonth: number
  planDescription: React.ReactNode
}

export type HgPricingCardProps = PricingCardHeadingProps & {
  buttonProps: HgButtonLinkProps
  pricingCardSections: PricingCardListProps[]
}

const CompanyListItem = ({companyName, companyLogo}: CompanyListItemProps) => {
  return (
    <div className="flex flex-row items-start space-x-s2">
      {companyLogo && (
        <div className="flex h-20 w-20 items-center justify-center">
          <div className="flex h-[16px] max-h-[16px] w-[16px] max-w-[16px] [&_*]:h-full [&_*]:w-full [&_*]:fill-icon-default">
            {companyLogo}
          </div>
        </div>
      )}
      <p className="text-text-default arcadia-body-3">{companyName}</p>
    </div>
  )
}

const GenericListItem = ({iconType, body, caption}: GenericListItemProps) => {
  return (
    <div className="flex flex-row items-start space-x-s2">
      {iconType && (
        <div className="flex h-20 w-20 items-center justify-center">
          <HgIcon className="text-icon-default" iconType={iconType} size="small" />
        </div>
      )}
      <div className="flex flex-col items-start">
        <p className="text-text-default arcadia-body-3 [&_br]:hidden md:[&_br]:block">
          {body}
        </p>
        {caption && (
          <p className="text-text-subdued arcadia-body-3 [&_br]:hidden md:[&_br]:block">
            {caption}
          </p>
        )}
      </div>
    </div>
  )
}

export const HgPricingCardList = ({heading, listItems}: PricingCardListProps) => {
  return (
    <article className="flex flex-col">
      <h2 className="pb-s2 text-text-default arcadia-heading-3">{heading}</h2>
      <ul className="flex flex-col space-y-s1">
        {listItems.map((item, index) => (
          <li key={index}>
            {'companyName' in item && 'companyLogo' in item ? (
              <CompanyListItem {...item} />
            ) : (
              <GenericListItem {...item} />
            )}
          </li>
        ))}
      </ul>
    </article>
  )
}

export const HgPricingCardHeading = ({
  planName,
  planIcon,
  pricePerMonth,
  planDescription,
}: PricingCardHeadingProps) => {
  return (
    <div className="flex flex-col">
      <header className="flex flex-row items-center justify-start space-x-s2 pb-s6">
        {planIcon && (
          <div className="flex h-[14px] max-h-[14px] w-[14px] max-w-[14px] items-center md:h-[16px] md:max-h-[16px] md:w-[16px] md:max-w-[16px] xl:h-[18px] xl:max-h-[18px] xl:w-[18px] xl:max-w-[18px] [&_*]:h-full [&_*]:w-full [&_*]:fill-icon-default">
            {planIcon}
          </div>
        )}
        <h1 className="text-text-default arcadia-heading-3">{planName}</h1>
      </header>
      <p className="text-text-default arcadia-heading-2">
        ${pricePerMonth}
        <span className="ps-s1 arcadia-body-3">/ Month</span>
      </p>
      <p className="text-text-subdued arcadia-body-3">{planDescription}</p>
    </div>
  )
}

export const HgPricingCard = ({
  buttonProps,
  pricingCardSections,
  className,
  ...headingProps
}: HgPricingCardProps & {className?: string}) => {
  return (
    <article className={className}>
      <HgPricingCardHeading {...headingProps} />
      <HgButtonLink className="w-full max-w-240" {...buttonProps} />
      <div className="space-y-s4 border-t border-border-subdued pt-s3">
        {pricingCardSections.map((section, index) => {
          return <HgPricingCardList key={index} {...section} />
        })}
      </div>
    </article>
  )
}
