'use client'

import {useCallback, useEffect, useRef, useState} from 'react'
import {tv, type VariantProps} from 'tailwind-variants'
import HgTag, {type HgTagProps} from './HgTag'

const tagGroupVariants = tv({
  slots: {
    tagsWrapper: 'flex w-full flex-row flex-wrap gap-s2 border-border-frosted',
  },
  variants: {
    canWrap: {
      false: {
        // Hide overflow tags to avoid slight flicker when tags wrap & are then hidden
        tagsWrapper: 'max-h-[30px] overflow-y-clip md:max-h-[32px]',
      },
    },
    variant: {
      default: {},
      withDivider: {
        tagsWrapper: 'border-s ps-s3',
      },
      withDividerStacked: {
        tagsWrapper: 'border-t pt-s3',
      },
    },
  },
  compoundVariants: [
    {
      variant: 'withDividerStacked',
      canWrap: false,
      class: {
        tagsWrapper: 'max-h-[calc(30px+var(--s3))] md:max-h-[calc(32px+var(--s3))]',
      },
    },
  ],
})

type HgTagGroupVariantProps = VariantProps<typeof tagGroupVariants>

export type HgTagGroupProps = {
  tags: HgTagProps[]
  maxTagsShown?: number
  canWrap?: boolean
} & HgTagGroupVariantProps

const NoTagsShown = () => (
  <div className="flex w-fit items-center justify-center py-s2 text-text-default arcadia-ui-2 md:arcadia-ui-1">
    No tags
  </div>
)

const HgTagGroup = ({
  tags,
  maxTagsShown,
  variant = 'default',
  canWrap = true,
}: HgTagGroupProps) => {
  const {tagsWrapper} = tagGroupVariants({variant, canWrap})
  const allTagsWrapperRef = useRef<HTMLDivElement | null>(null)
  const overflowTagRef = useRef<HTMLDivElement | null>(null)
  const [allTags, setAllTags] = useState(tags)
  const [wrappingElementIndex, setWrappingElementIndex] = useState<number | null>(
    null
  )

  // Calculate # of tags that are hidden
  const calculateOverflowNumber = useCallback(() => {
    const numberOfVisibleTags = wrappingElementIndex
      ? allTags.slice(wrappingElementIndex).length
      : allTags.length - (maxTagsShown || 0)

    return numberOfVisibleTags >= allTags.length ? 0 : numberOfVisibleTags
  }, [maxTagsShown, allTags, wrappingElementIndex])

  const [overflowNumber, setOverflowNumber] = useState(calculateOverflowNumber())

  useEffect(() => {
    setOverflowNumber(calculateOverflowNumber)
  }, [calculateOverflowNumber])

  const handleDismiss = (dismissedTag: HgTagProps) => {
    setAllTags([...allTags.filter(tag => tag.id !== dismissedTag.id)])
  }

  // Find the index of the first tag that wraps to the next line
  const findWrappingElementIndex = useCallback(() => {
    if (!allTagsWrapperRef.current || canWrap) return

    const tagElements = allTagsWrapperRef.current.children
    const startingTopPoint = tagElements[0].getBoundingClientRect().top
    const firstWrappingVisibleTagIndex = Array.from(tagElements).findIndex(
      child => child.getBoundingClientRect().top !== startingTopPoint
    )

    const isOverflowTagWrapping =
      overflowTagRef.current &&
      overflowTagRef.current?.getBoundingClientRect().top !== startingTopPoint

    // The "overflow" tag should never be hidden
    const firstWrappingElementIndex = isOverflowTagWrapping
      ? firstWrappingVisibleTagIndex - 1
      : firstWrappingVisibleTagIndex

    setWrappingElementIndex(
      firstWrappingElementIndex > 0 ? firstWrappingElementIndex : null
    )
  }, [canWrap])

  useEffect(() => {
    // If tags are allowed to wrap, no need to calculate wrapping element
    if (!allTagsWrapperRef.current || canWrap) return

    findWrappingElementIndex()

    const resizeObserver = new ResizeObserver(findWrappingElementIndex)
    resizeObserver.observe(allTagsWrapperRef.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [canWrap, findWrappingElementIndex])

  // Swap order of tags & hide wrapping tags if tags shouldn't be seen wrapping
  const shouldHideTag = (index: number) =>
    canWrap || !wrappingElementIndex || index < wrappingElementIndex
  const shouldShowOverflowTag = overflowNumber > 0 && overflowNumber < allTags.length

  return (
    <div className={tagsWrapper()} ref={allTagsWrapperRef}>
      {allTags.length > 0 ? (
        allTags.map((tag, index) => {
          return !maxTagsShown || index < maxTagsShown ? (
            <HgTag
              key={tag.id}
              {...tag}
              onDismiss={() => {
                handleDismiss(tag)
              }}
              className={
                // Need to hide tags from screen readers & change order to allow "overflow" tag to be last visible tag
                shouldHideTag(index) ? 'visible order-1' : 'invisible order-3'
              }
            />
          ) : null
        })
      ) : (
        <NoTagsShown />
      )}
      <div ref={overflowTagRef} className="order-2">
        {shouldShowOverflowTag && (
          <HgTag id="no-tags" body={`+${overflowNumber} more`} />
        )}
      </div>
    </div>
  )
}

export default HgTagGroup
